@media (min-width: 768px) {
  header .menu {
    clear: none;
    float: right;
    max-height: none;
    /*width: 100%;*/
  }
  header .menu-icon {
    display: none;
  }

  .on-mobile {
    display: none;
  }

  .next-btn:hover {
    padding: 12px 50px;
  }

  .previous-btn:hover {
    padding: 12px 50px;
  }

  .generate-btn:hover {
    width: auto;
    height: 48px;
    display: inline-flex;
    background: #fff;
    color: #1e85ff;
    border: 1px solid #1e85ff;
  }

  .anamnese-detail .text-box ul li button {
    min-width: 16vw;
  }
}

@media (max-width: 1600px) {
  header {
    padding: 30px 0;
  }
  .preface-sec {
    padding: 20px 0px 0;
  }
  .title h2 {
    font-size: 24px;
  }
  .preface-inner {
    padding: 50px 15px;
    margin: 30px 0 0 0;
  }
  .preface-inner .text-box h4 {
    font-size: 20px;
    margin-bottom: 14px;
  }
  .preface-inner .text-box p {
    font-size: 20px;
    line-height: 30px;
  }
  /* .preface-inner .image-holder {
        position: relative;
        margin: 50px 0;
    } */
  .preface-inner .text-box .link a {
    margin: 30px auto 0;
  }
  footer {
    padding: 30px 0;
  }
  .anamnese-inner {
    display: flex;
    margin: 30px 0 0 0;
  }
  .anamnese-sec {
    padding: 20px 30px 0;
  }
  .anamnese-title {
    margin-bottom: 30px;
  }
  .anamnese-steps {
    margin: 90px 0 0 0;
    max-width: 280px;
  }
  .anamnese-steps ul:after {
    left: 30px;
  }
  .anamnese-steps ul li p {
    padding-left: 15px;
    font-size: 14px;
  }
  .anamnese-steps.anamnese-steps-right ul li p {
    padding-left: 0;
    padding-right: 15px;
  }
  .anamnese-detail {
    padding: 30px 80px;
    width: calc(100% - 560px);
  }
  .anamnese-detail .text-box h4 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .anamnese-detail .text-box {
    margin-bottom: 50px;
  }
  /* .anamnese-steps ul li:after{
        left: -30px;
    } */
  .anamnese-steps.anamnese-steps-right ul li:after {
    left: auto;
    right: -26px;
  }
  .research-third-detail .text-box ul li {
    padding: 0 5px;
  }
  .anamnese-detail .text-box ul li {
    margin-bottom: 15px;
  }
  .pice-slider {
    margin-bottom: 50px;
  }
  .anamnese-steps.anamnese-steps-right ul {
    padding: 15px 0;
  }
  .anamnese-steps.anamnese-steps-right ul li span {
    width: 22px;
    height: 22px;
    font-size: 12px;
  }
  .anamnese-steps.anamnese-steps-right ul:after {
    left: auto;
    right: 12px;
  }
}
@media (max-width: 1300px) {
  .anamnese-sec {
    padding: 20px 10px 0;
  }
  /* .anamnese-steps ul li:after {
        left: -21px;
    } */
  .anamnese-steps.anamnese-steps-right ul li:after {
    left: auto;
    right: -21px;
  }
  .anamnese-detail {
    padding: 30px 70px;
    width: calc(100% - 520px);
  }
  .anamnese-steps {
    max-width: 260px;
  }
}
@media (max-width: 1199px) {
  header {
    padding: 20px 0;
  }
  .title h2 {
    font-size: 20px;
  }
  .preface-inner .text-box p {
    font-size: 18px;
    line-height: 26px;
  }
  .anamnese-steps ul li span {
    width: 45px;
    font-size: 20px;
    height: 45px;
  }
  .anamnese-steps ul li p {
    padding-left: 10px;
    font-size: 12px;
  }
  .anamnese-steps.anamnese-steps-right ul li p {
    padding-left: 0;
    padding-right: 10px;
  }
  .anamnese-steps {
    max-width: 225px;
  }
  .anamnese-detail {
    padding: 20px 60px;
    width: calc(100% - 450px);
  }
  .anamnese-title h4 {
    font-size: 26px;
  }
  .anamnese-detail .text-box h4 {
    line-height: 22px;
    margin-bottom: 10px;
  }
  .anamnese-detail .text-box h4 br {
    display: none;
  }
  .anamnese-detail .text-box {
    margin-bottom: 40px;
  }

  .anamnese-steps.anamnese-steps-right ul li {
    margin-bottom: 15px;
  }
  .research-third-detail .text-box ul li a {
    max-width: inherit;
    padding: 0 40px;
    height: 45px;
    font-size: 14px;
  }
  .anamnese-detail .text-box ul li a span {
    left: 13px;
  }
  .anamnese-detail .text-box ul li a span img {
    height: 15px;
  }
  .anamnese-detail .text-box .pice-slider p {
    max-width: 160px;
    padding-right: 15px;
  }
  .completion-inner {
    padding: 15px;
    margin-bottom: 30px;
  }
  .completion-box .text-inner h5 {
    margin-bottom: 20px;
  }
  .completion-detail .text-inner {
    margin-bottom: 20px;
  }
  .completion-box .text-inner h6 {
    margin-bottom: 15px;
  }
  .completion-box .text-inner ul.text-inner-second li {
    margin-bottom: 15px;
  }
  .success-sec .image-holder {
    margin: 0 0 30px 0;
  }
  .success-sec .image-holder img {
    height: 90px;
  }
  .success-sec p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .report-email h4 {
    font-size: 18px;
  }
  .report-email form a {
    width: 160px;
    margin-left: 15px;
  }
  .anamnese-steps ul:after {
    left: 21px;
  }

  .modal-window .modal-window-inner {
    max-width: 95%;
    padding: 30px 15px;
  }
  .modal-window .modal-window-inner h4 {
    font-size: 18px;
  }
  .modal-window .modal-window-inner p {
    font-size: 18px;
    margin: 30px auto;
    line-height: 26px;
  }
  .modal-window .modal-window-inner li a {
    height: 60px;
    height: 45px;
    font-size: 14px;
    padding: 0px 25px;
  }
}
@media (max-width: 991px) {
  .vue-slider-ltr .vue-slider-mark-label {
    font-size: 10px;
  }

  .title h2 {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .navigation {
    opacity: 1;
  }

  .navigation img {
    height: 44px;
  }
  .preface-inner .text-box .link a {
    max-width: 240px;
    height: 50px;
    font-size: 14px;
  }
  .steps-mobile-top {
    display: block;
    margin-top: 30px;
  }
  .anamnese-steps {
    display: none;
  }
  .anamnese-detail {
    padding: 32px 40px;
    width: 100%;
  }
  .research-third-detail textarea.form-control {
    height: 140px;
    padding: 60px 0;
  }
  .anamnese-title {
    margin-bottom: 20px;
  }
  .completion-box {
    padding: 10px 15px 0px 0;
  }
  .anamnese-detail.completion-detail .text-box {
    margin-bottom: 20px;
  }
  .anamnese-inner {
    margin: 0;
  }
  .report-email {
    margin: 30px 0 0 0;
  }

  .anamnese-detail .text-box ul li button {
    min-width: 50vw !important;
  }
}
@media (max-width: 767px) {
  .anamnese-detail .text-box h4 {
    font-size: 14px;
    flex: 80%;
  }
  .tooltip-circle {
    width: 20px;
    height: 20px;
    background-color: #1e85ff;
    color: #fff;
    padding: 1px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-left: 16px;
    opacity: 0.3;
  }

  .navigation a {
    display: flex;
    align-items: center;
  }
  header .menu {
    width: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fff;
    top: 50px;
    max-height: inherit;
    height: 0;
    transition: height 0.1s ease-out;
  }
  header .menu-btn:checked ~ .menu {
    height: auto;
    transition: height 0.1s ease-out;
    z-index: 10;
  }

  .on-mobile {
    display: block;
  }

  header ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  header li {
    width: 100%;
  }
  header {
    padding: 15px 0;
  }
  header li {
    margin-left: 0;
  }
  header li a {
    font-size: 15px;
    padding: 10px 15px;
  }
  .navigation img {
    height: 34px;
  }
  header .menu-icon {
    padding: 10px;
  }
  .preface-sec {
    padding: 15px 0px 0;
  }
  .title h2 {
    font-size: 16px;
  }
  .preface-inner {
    padding: 30px 15px;
  }
  .preface-inner .text-box p {
    font-size: 16px;
    line-height: 24px;
  }
  /* .preface-inner .image-holder { 
        margin: 30px 0;
    } */
  .preface-inner .image-holder .text-inner a img {
    height: 50px;
  }
  .preface-inner .image-holder .text-inner p {
    line-height: 26px;
    font-size: 16px;
    margin-top: 5px;
  }

  textarea.form-control {
    font-size: 15px;
  }
  .research-third-detail .text-box ul li a {
    font-size: 12px;
  }
  .success-sec .image-holder img {
    height: 61px;
  }
  .success-sec .image-holder {
    margin: 0 0 20px 0;
  }
  .success-sec p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 14px;
  }
  .report-email h4 {
    margin-bottom: 20px;
  }
  .modal-window .modal-window-inner p {
    font-size: 16px;
    margin: 25px auto;
    line-height: 26px;
  }
}
@media (max-width: 575px) {
  .title h2 {
    font-size: 16px;
  }
  .navigation img {
    height: 28px;
  }

  .next-btn {
    width: 100px;
    padding: 12px 12px;
  }

  .anamnese-detail {
    padding: 32px 24px;
  }

  .preface-inner .text-box p {
    font-size: 14px;
    line-height: 24px;
  }
  .preface-inner .image-holder .text-inner {
    padding: 0 15px;
  }
  .preface-inner .text-box .link a {
    max-width: 180px;
    height: 45px;
    font-size: 14px;
  }
  .preface-inner .image-holder .text-inner a img {
    height: 40px;
  }
  .preface-inner .image-holder .text-inner p {
    line-height: 18px;
    font-size: 14px;
    margin-top: 5px;
  }
  .anamnese-detail .text-box h4 {
    font-size: 14px;
  }
  .anamnese-detail .text-box h4 {
    line-height: 20px;
    margin-bottom: 10px;
  }
  .research-third-detail .text-box ul li {
    padding: 0 3px;
  }
  .research-third-detail .text-box ul {
    margin: 0 -3px;
  }
  .anamnese-detail .text-box ul li a {
    padding: 12px 12px 12px 28px;
  }

  .pice-slider {
    flex-direction: column;
  }
  .anamnese-detail .text-box .pice-slider p {
    max-width: 100%;
    padding-right: 0;
    text-align: center;
    margin: 0 0 10px 0;
  }
  .research-third-detail textarea.form-control {
    height: 100px;
    padding: 40px 0;
  }
  footer {
    padding: 20px 0;
  }
  .anamnese-title h4 {
    font-size: 22px;
  }
  .completion-inner {
    padding: 15px 10px;
    margin-bottom: 30px;
  }
  .completion-box .text-inner h5 {
    font-size: 16px;
  }
  .completion-box .text-inner ul li {
    font-size: 14px;
  }
  .completion-detail .text-inner {
    margin-bottom: 15px;
  }
  .completion-box .text-inner h5 {
    margin-bottom: 10px;
  }
  .success-sec ul {
    flex-wrap: wrap;
    margin: 0 -5px;
  }
  .success-sec ul li {
    padding: 10px 5px;
  }
  .report-email form {
    flex-direction: column;
  }
  .report-email form a {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
  .modal-close {
    right: -15px;
    top: 20px;
    width: 40px;
  }
  .modal-window .modal-window-inner p {
    font-size: 14px;
    margin: 20px auto;
    line-height: 24px;
  }
  .modal-window .modal-window-inner ul {
    flex-direction: column;
    margin: 0 0;
  }
  .modal-window .modal-window-inner li {
    padding: 5px 0;
    width: 100%;
  }
  .modal-window .modal-window-inner li a {
    justify-content: center;
  }
  .completion-box::-webkit-scrollbar {
    width: 5px;
  }
  .steps-mobile a p {
    font-size: 12px;
  }
  .steps-mobile a span {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .steps-mobile-top:after {
    top: 40px;
  }

  .anamnese-detail .text-box ul li button {
    min-width: auto;
    width: 100%;
  }
}
@media (max-width: 374px) {
  textarea.form-control {
    font-size: 15px;
  }
  .anamnese-detail .text-box {
    margin-bottom: 32px;
  }
  .anamnese-detail .text-box p {
    font-size: 14px;
  }
  .research-third-detail .text-box ul {
    margin: 0;
  }
  .research-third-detail .text-box ul li {
    padding: 0;
    width: 100%;
  }
  .anamnese-detail .text-box ul li a {
    font-size: 12px;
  }
  .anamnese-detail .text-box ul li.active a span img {
    height: 15px;
  }
  .completion-inner {
    padding: 15px 5px 15px 10px;
    margin-bottom: 15px;
  }
  .completion-box {
    padding: 10px 5px 0px 0;
  }
  .success-sec ul {
    margin: 0;
  }
  .success-sec ul li {
    padding: 10px 0;
    width: 100%;
  }
  .success-sec ul li a {
    border-radius: 20px;
    padding: 20px 10px;
    width: 100%;
  }
  .report-email h4 {
    font-size: 14px;
  }
}
