@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;500;700&display=swap");

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueThin.eot");
  src: url("../fonts/HelveticaNeueThin.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueThin.woff2") format("woff2"), url("../fonts/HelveticaNeueThin.woff") format("woff"), url("../fonts/HelveticaNeueThin.ttf") format("truetype"), url("../fonts/HelveticaNeueThin.svg#HelveticaNeueThin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue-ThinItalic.eot");
  src: url("../fonts/HelveticaNeue-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-ThinItalic.woff2") format("woff2"), url("../fonts/HelveticaNeue-ThinItalic.woff") format("woff"), url("../fonts/HelveticaNeue-ThinItalic.ttf") format("truetype"), url("../fonts/HelveticaNeue-ThinItalic.svg#HelveticaNeue-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue.eot");
  src: url("../fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue.woff2") format("woff2"), url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.ttf") format("truetype"), url("../fonts/HelveticaNeue.svg#HelveticaNeue") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue-Bold.eot");
  src: url("../fonts/HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-Bold.woff2") format("woff2"), url("../fonts/HelveticaNeue-Bold.woff") format("woff"), url("../fonts/HelveticaNeue-Bold.ttf") format("truetype"), url("../fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f4f6f8;
  padding: 0;
  margin: 0;
}

a.active,
a:focus,
button:focus,
button.active {
  outline: none;
}

a {
  text-decoration: none;
}

a:focus,
a:hover,
a:active {
  outline: 0;
  box-shadow: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

img {
  border-style: none;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.wrapper {
  overflow: hidden;
}
.custom-container {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.full-container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
/********** THANKS PAGE ********/
header {
  padding: 70px 0;
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  opacity: 0.5;
  transition: all 250ms ease-in-out;
}

.navigation:hover {
  opacity: 1;
}
header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
  align-items: center;
}
header li {
  margin-left: 24px;
}
header li:first-child {
  margin-left: 0;
}
header li a {
  display: block;
  text-decoration: none;
  color: #535b66;
  font-size: 15px;
  font-weight: 500;
}
header li a:hover {
  color: #1e85ff;
}

/* menu */

header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

header .menu-icon {
  cursor: pointer;
  /* display: inline-block; */
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

header .menu-icon .navicon:before,
header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

header .menu-icon .navicon:before {
  top: 5px;
}

header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

header .menu-btn {
  display: none;
}

header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
/******** PREFACE SEC ******/
.title {
  text-align: center;
}
.title h2 {
  color: #b2becc;
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 24px;
  font-weight: 400;
  transition: all 250ms ease-in-out;
}

.title h2:hover {
  color: #000;
}

.preface-sec {
  padding: 0px 0px 0;
}
.preface-inner {
  border-radius: 10px;
  background: #fff;
  padding: 75px 15px 65px;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
  margin: 70px 0 0 0;
}
.preface-box {
  max-width: 879px;
  width: 100%;
  margin: 0 auto;
}
.preface-inner .text-box {
  text-align: center;
}
.preface-inner .text-box h4 {
  color: #1c2437;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 22px;
}
.preface-inner .text-box p {
  color: #1c2437;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  max-width: 879px;
  width: 100%;
  margin: 0 auto;
}
.preface-inner .text-box .link a {
  background-color: #1e85ff;
  border-radius: 30px;
  max-width: 377px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 103px auto 0;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #1e85ff;
}
.preface-inner .text-box .link a:hover {
  background-color: transparent;
  color: #1e85ff;
}
.preface-inner .image-holder {
  position: relative;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 48px;
  width: 80%;
}
.preface-inner .image-holder .text-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.preface-inner .image-holder .text-inner p {
  color: #ffffff;
  font-weight: bold;
  line-height: 36px;
  font-size: 18px;
  margin-top: 8px;
}
.preface-inner .image-holder .text-inner p span {
  display: block;
  font-weight: 400;
}

/****** FOOTER *****/
footer {
  padding: 120px 0 76px;
}
.footer-inner {
  text-align: center;
}
.footer-inner p {
  color: #525b66;
  font-size: 14px;
  font-weight: 400;
}
/******* ANAMNESE SEC ******/
.anamnese-sec {
  padding: 0px 88px 0;
}
.anamnese-inner {
  display: flex;
  margin: 70px 0 0 0;
}
/* 
.sub-title-anamnese-left {
  margin-bottom: 16px;
  color: #1e85ff;
  font-weight: 500;
  font-size: 18px;
}

.sub-title-anamnese-right {
  margin-bottom: 16px;
  color: #1e85ff;
  font-weight: 500;
  font-size: 18px;
} */

.anamnese-steps {
  max-width: 400px;
  width: 100%;
  margin: 0;
  position: fixed;
  left: 42px;
  top: 50%;
  transform: translate(0, -50%);
}

.anamnese-steps-left {
  text-align: start;
  opacity: 0.5;
  transition: all 250ms ease-in-out;
}

.anamnese-steps-left:hover {
  opacity: 1;
}

.anamnese-steps-right {
  text-align: end;
  opacity: 0.5;
  transition: all 250ms ease-in-out;
}

.anamnese-steps-right:hover {
  opacity: 1;
}

.anamnese-steps ul {
  position: relative;
  padding: 30px 0;
}
.anamnese-steps ul:after {
  position: absolute;
  content: "";
  left: 27px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #1e85ff;
}
.anamnese-steps ul li {
  margin-bottom: 20px;
  position: relative;
}
.anamnese-steps ul li:after {
  position: absolute;
  content: "";
  left: -23px;
  top: 50%;
  transform: translate(0, -50%);
  background: url("../images/check-icon2.svg") no-repeat;
  background-size: 13px;
  width: 18px;
  height: 18px;
  opacity: 0;
  visibility: hidden;
}
.anamnese-steps ul li.complete:after {
  opacity: 1;
  visibility: visible;
}
.anamnese-steps ul li.active span {
  background-color: #1e85ff;
  color: #fff;
}
.anamnese-steps ul li.success span {
  background-color: #70d068;
  border-color: #70d068;
  color: #fff;
}
.anamnese-steps ul li.active p,
.anamnese-steps ul li.success p {
  font-weight: bold;
  color: #000;
}
.anamnese-steps ul li.success:after {
  opacity: 1;
  visibility: visible;
  background: url("../images/check-icon-green.svg") no-repeat;
  background-size: cover;
}
.anamnese-steps ul li:last-child {
  margin-bottom: 0;
}
.anamnese-steps ul li a {
  display: flex;
  position: relative;
  z-index: 10;
  align-items: center;
}
.anamnese-steps ul li span {
  border: 1px solid #1e85ff;
  background-color: #fff;
  width: 55px;
  height: 55px;
  color: #1e85ff;
  font-size: 18px;
  font-weight: normal;
  display: flex;
  align-items: center;
  font-family: "Helvetica Neue";
  justify-content: center;
  border-radius: 50%;
}
.anamnese-steps ul li p {
  color: #c3c3c3;
  font-family: "Helvetica Neue";
  font-weight: normal;
  padding-left: 20px;
  font-size: 18px;
}
.anamnese-detail {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
  padding: 45px 75px 66px;
  width: calc(100% - 650px);
  margin: 0 auto;
}
.anamnese-title {
  text-align: center;
  margin-bottom: 80px;
}
.anamnese-title span {
  color: #b2becc;
  display: block;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 14px;
}
.anamnese-title h4 {
  color: #000;
  font-size: 28px;
  font-weight: 500;
}
.anamnese-detail .text-box {
  margin-bottom: 75px;
  text-align: start;
}
.anamnese-detail .text-box h4 {
  color: #030303;
  font-size: 20px;
  display: inline-block;
  font-weight: 500;
  line-height: 40px;
  margin: 0 auto;
  margin-bottom: 34px;
  text-align: start;
  flex: 90%;

  /* max-width: 90%; */
  /* margin-left: 16px;
  text-indent: -24px; */
}

.anamnese-detail .text-box p {
  color: #030303;
  font-size: 15px;
  font-weight: 400;
  text-align: start;
}
.anamnese-detail .text-box ul {
  margin-bottom: 21px;
}
.anamnese-detail .text-box ul li {
  margin-bottom: 21px;
}
.anamnese-detail .text-box ul li:last-child {
  margin-bottom: 0;
}

.anamnese-detail .text-box ul li.active button,
.anamnese-detail .text-box ul li button:hover {
  border: 2px solid rgba(30, 133, 255, 0.3);
  color: #1e85ff;
  font-weight: bold;
}
.anamnese-detail .text-box ul li.active button span {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s ease-in-out;
}
.anamnese-detail .text-box ul li button:hover span {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s ease-in-out;
}

.anamnese-detail .text-box ul li button {
  display: block;
  align-items: center;
  justify-content: center;
  color: #1c2437;
  position: relative;
  font-size: 15px;
  margin: 0 auto;
  height: auto;
  border-radius: 27.5px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: 12px 12px 12px 40px;
  text-align: center;
}

.anamnese-detail .text-box ul li button span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
}

textarea.form-control {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  max-width: 624px;
  width: 100%;
  height: 88px;
  border-radius: 27.5px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 16px 33px 16px;
  resize: none;
}

textarea.form-control::placeholder {
  color: #b2becc;
  padding: 16px 24px;
  text-align: center;
}

.next-btn {
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #1e85ff;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #1e85ff;
  cursor: pointer;
  padding: 12px 32px;
  margin: 0px 8px;
}

.generate-btn {
  width: auto;
  height: 48px;
  display: inline-flex;
  background: #1e85ff;
  color: #fff;
  border: 1px solid #1e85ff;
  padding: 12px 32px;
}

.previous-btn {
  background: #fff;
  color: #1e85ff;
}

.button-row {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.error-text {
  color: red;
  text-align: start;
  font-size: 14px;
  line-height: 18px;
}

.anamnese-steps.anamnese-steps-right {
  left: auto;
  right: 42px;
}
.anamnese-steps.anamnese-steps-right ul {
  padding: 35px 0 25px;
}
.anamnese-steps.anamnese-steps-right ul:after {
  left: auto;
  right: 15px;
}
.anamnese-steps.anamnese-steps-right ul li {
  margin-bottom: 12px;
}
.anamnese-steps.anamnese-steps-right ul li:after {
  left: auto;
  right: -25px;
}
.anamnese-steps.anamnese-steps-right ul li a {
  justify-content: flex-end;
}
.anamnese-steps.anamnese-steps-right ul li:last-child {
  margin-bottom: 0;
}
.anamnese-steps.anamnese-steps-right ul li span {
  width: 30px;
  height: 30px;
  font-size: 16px;
}
.anamnese-steps.anamnese-steps-right ul li p {
  padding-left: 0;
  padding-right: 20px;
}
/******* RESEARCH THIRD QUESTION ******/
.research-third-detail .text-box ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.research-third-detail .text-box ul li {
  padding: 0 15px;
}
.research-third-detail .text-box ul li a {
  max-width: inherit;
  padding: 0 60px;
}
.pice-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  max-width: 870px;
  width: 100%;
  margin: 0 auto 80px;
}
.pice-slider:last-child {
  margin-bottom: 0;
}
.anamnese-detail .text-box .pice-slider p {
  font-weight: 500;
  max-width: 180px;
  width: 100%;
  padding-right: 30px;
  text-align: right;
  margin: 0;
}
.anamnese-detail .text-box .pice-slider span {
  display: block;
  flex: 1;
  width: 100%;
}
.anamnese-detail .text-box .pice-slider span img {
  width: 100%;
}
.research-third-detail textarea.form-control {
  height: 191px;
  padding: 83px 0;
}
/******* COMPLETION ******/
.anamnese-detail.completion-detail .text-box {
  margin-bottom: 29px;
}
.anamnese-detail.completion-detail .text-box h4 {
  margin-bottom: 0;
}
.completion-inner {
  max-width: 836px;
  width: 100%;
  margin: 0 auto;
  padding: 23px 20px 23px 42px;
  border-radius: 10px;
  background: #fff;
  border: 0.6px solid #707070;
  margin-bottom: 82px;
}
.completion-box {
  height: 500px;
  overflow-y: scroll;
  padding: 30px 0 0 0;
}
.completion-box::-webkit-scrollbar {
  width: 10px;
}

.completion-box::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 0.6px solid #707070;
}

.completion-box::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #1e85ff;
}
.completion-detail .text-inner {
  margin-bottom: 45px;
}
.completion-box .text-inner h5 {
  color: #707070;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}
.completion-box .text-inner ul span {
  color: #707070;
  font-family: "Helvetica Neue";
  font-size: 14px;
}
.completion-box .text-inner ul li {
  color: #707070;
  font-size: 16px;
  line-height: 18px;
  font-family: "HelveticaNeue";
  font-weight: 100;
  display: flex;
}
.completion-box .text-inner ul.text-inner-second li {
  margin-bottom: 24px;
}
.completion-box .text-inner ul li strong {
  color: #707070;
  font-weight: 100;
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
}
.completion-box .text-inner h6 {
  color: #707070;
  font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 32px;
}
/********* THANKS PAGE ******/
.success-sec {
  text-align: center;
}
.success-sec .image-holder {
  margin: 0 0 120px 0;
}
.success-sec p {
  color: #030303;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 30px;
}
.success-sec ul {
  display: flex;
  align-items: center;
  margin: 0 -12px;
  justify-content: center;
}
.success-sec ul li {
  padding: 12px;
}
.success-sec ul li a {
  width: 134px;
  border-radius: 27.5px;
  background: #1e85ff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 10px 21px;
}
.success-sec ul li a span {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  display: block;
  margin-top: 13px;
}
.report-email {
  margin: 120px 0 0 0;
}
.report-email h4 {
  color: #030303;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 35px;
  text-align: center;
}
.report-email form {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 592px;
  width: 100%;
  margin: 0 auto;
}
.report-email form .relative {
  position: relative;
  flex: 1;
  width: 100%;
}
.report-email form .form-control {
  width: 100%;
  height: 44px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 30px;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
.report-email form span {
  position: absolute;
  top: 12px;
  left: 20px;
}
.report-email form a {
  width: 213px;
  height: 44px;
  border-radius: 22px;
  background: #1e85ff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
}
/******* VIDEO MODAL *******/

.modal-window .modal-window-inner {
  width: 100%;
  max-width: 1009px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 68px 0 36px;
  background-color: #ffffff;
}
.modal-window .modal-window-inner h4 {
  color: #1c2437;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.modal-window .modal-window-inner p {
  color: #1c2437;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  max-width: 890px;
  width: 100%;
  margin: 35px auto 60px;
  line-height: 36px;
}
.modal-window .modal-window-inner ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
}
.modal-window .modal-window-inner li {
  padding: 0 10px;
}
.modal-window .modal-window-inner li a {
  height: 60px;
  border-radius: 30px;
  background: #1e85ff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 1px solid #1e85ff;
  padding: 0 56px;
  cursor: pointer;
}
.modal-window .modal-window-inner li a:hover {
  background-color: transparent;
  color: #1e85ff;
}
.modal-window .modal-window-inner li a.star-btn {
  background-color: transparent;
  color: #1e85ff;
}
.modal-window .modal-window-inner li a.star-btn:hover {
  background-color: #1e85ff;
  color: #fff;
}
.modal-close {
  position: absolute;
  right: -25px;
  top: 35px;
  width: 70px;
  cursor: pointer;
}
.modal-close:after {
  content: "";
  position: absolute;
  height: 5px;
  width: 50%;
  top: 50%;
  left: 0;
  background: #cbcbcb;
  border-radius: 5px;
  margin-top: -6px;
  transform: rotate(-45deg);
}
.modal-close:before {
  content: "";
  position: absolute;
  height: 5px;
  width: 50%;
  top: 50%;
  left: 0;
  background: #cbcbcb;
  border-radius: 5px;
  margin-top: -6px;
  transform: rotate(45deg);
}
/******** MOBILE STEPS ******/
.steps-mobile-top {
  text-align: center;
  position: relative;
  display: none;
}
.steps-mobile-top:after {
  position: absolute;
  content: "";
  left: 0;
  top: 45px;
  width: 100%;
  background-color: #1e85ff;
  height: 1px;
}
.steps-mobile.active a span,
.steps-mobile.complete span {
  background-color: #1e85ff;
  color: #fff;
}
.steps-mobile.active a p {
  font-weight: bold;
  color: #000;
}
.steps-mobile {
  position: relative;
}
.steps-mobile a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.steps-mobile a span {
  border: 1px solid #1e85ff;
  background-color: #fff;
  width: 50px;
  height: 50px;
  color: #1e85ff;
  font-size: 18px;
  position: relative;
  z-index: 1;
  font-weight: normal;
  display: flex;
  align-items: center;
  font-family: "Helvetica Neue";
  justify-content: center;
  border-radius: 50%;
}
.steps-mobile a p {
  color: #c3c3c3;
  font-family: "Helvetica Neue";
  font-weight: normal;
  padding-left: 0;
  font-size: 14px;
  margin-top: 10px;
}

.steps-mobile:after {
  position: absolute;
  content: "";
  left: 50%;
  top: -18px;
  transform: translate(-50%, 0);
  background: url("../images/check-icon2.svg") no-repeat;
  background-size: 13px;
  width: 18px;
  height: 18px;
  opacity: 0;
  visibility: hidden;
}
.steps-mobile.complete:after {
  opacity: 1;
  visibility: visible;
}
.steps-mobile.success:after {
  position: absolute;
  content: "";
  left: 50%;
  top: -18px;
  transform: translate(-50%, 0);
  background: url("../images/check-icon-green.svg") no-repeat;
  background-size: 13px;
  width: 18px;
  height: 18px;
  opacity: 0;
  visibility: hidden;
}
.steps-mobile.success:after {
  opacity: 1;
  visibility: visible;
}
.steps-mobile.success span {
  background-color: #70d068;
  color: #fff;
  border-color: #70d068;
}
.steps-mobile.success p {
  color: #000;
  font-weight: bold;
}
.swiper-container {
  padding: 20px 0;
}
/****** RANGE SLIDER *****/
.slider-wrapper {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
}
.slider-wrapper strong {
  color: #1e85ff;
  font-size: 18px;
  padding: 0 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.slider-wrapper strong.number {
  margin-right: 10px;
  margin-left: 0;
}
.noUi-target {
  width: 100%;
  border: none;
  background-color: #e5e5e5;
  box-shadow: none;
  height: 2px;
}
.noUi-connect {
  background: #1e85ff;
}
.noUi-horizontal .noUi-handle {
  width: 37px;
  height: 37px;
  box-shadow: none;
  background: #fff;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08));
  border-radius: 50px;
  border: none;
  padding: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 0;
  top: 50%;
}
.noUi-tooltip {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  display: flex;
  align-items: center;
  color: #1e85ff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

/* tooltip */

[data-tooltip] {
  position: relative;
  z-index: 10;
  cursor: pointer;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: #000;
  background-image: linear-gradient(30deg, #000, #000, #000);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
  width: 200px;
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px; /* CSS triangle */
  border-color: #000 transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
								the transition effects for the 
								tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
								slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* 
	Arrow slide down effect only on mouseenter (NOT on mouseleave)
  */
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

.tooltip-circle {
  width: 32px;
  height: 32px;
  background-color: #1e85ff;
  color: #fff;
  padding: 4px;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  margin-left: 16px;
  opacity: 0.3;
}

.tooltip-circle:hover {
  opacity: 1;
}

.align-items {
  display: flex;
  align-items: flex-start; /*Aligns vertically center */
  justify-content: flex-start; /*Aligns horizontally center */
}

.anamnese-detail-research {
  margin-bottom: 0px !important;
}

.toelichting-research {
  margin-bottom: 40px;
}

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent #000;
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* Spinner */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 80px;
  margin-left: 16px;
}
.lds-ellipsis div {
  position: absolute;
  top: 35px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
