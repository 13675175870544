
.eps-slider {
  margin-top: 20px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 700px) {
  .eps-slider {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.vue-slider-process {
  background-color: #1e85ff !important;
}

.vue-slider-dot-tooltip-inner {
  border-color: #1e85ff !important;
  background-color: #1e85ff !important;
}

.vue-slider-rail {
  background-color: #c3c3c3 !important;
}

.question-check {
  margin-bottom: 12px !important;
}

.answer-box-check {
  text-align: start;
  padding: 0px 16px 16px 0px;
}

.check-subtitle {
  padding: 32px 0px 24px 0px;
  text-align: start;
}
