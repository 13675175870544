
p {
  text-align: start;
  margin-bottom: 26px !important;
}

h4 {
  margin-bottom: 40px !important;
}

.normal_link {
  color: #1e85ff;
}

.normal_link:hover {
  color: #c7c7c7;
}
