
@media (min-width: 991px) {
  .anamnese-detail .text-box ul li {
    display: inline-block;
    margin: 0px 8px 8px 8px;
  }
  .anamnese-detail .text-box ul li a {
    min-width: 225px;
  }
}
@media (min-width: 1442px) {
  .anamnese-detail .text-box ul li {
    display: inline-block;
    margin: 0px 8px 8px 8px;
  }
  .anamnese-detail .text-box ul li a {
    min-width: 300px;
  }
}
