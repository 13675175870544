
.normal_link {
  color: #1e85ff;
}

.normal_link:hover {
  color: #c7c7c7;
}

.text-box .link a {
  background-color: #1e85ff;
  border-radius: 30px;
  width: 20%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #1e85ff;
  position: fixed;
  bottom: 5%;
  margin-left: 40%; /* Negative half of width. */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 600px) {
  .text-box .link a {
    width: 80%;
    margin-left: 10%; /* Negative half of width. */
    bottom: 4%;
  }
}
.text-box .link a:hover {
  background-color: #fff;
  color: #1e85ff;
}

* {
  text-align: left;
}

h4 {
  font-size: 22px !important;
}
